<template>
  <page-content>

    <common-table
      ref="table"
      path="role"
      :columns="columns"
      >

      <template slot="search">
        <a-button v-hasPermission="['role:add']" ghost type="primary" @click="add">新增</a-button>
        <!--        <a-button v-hasPermission="['role:delete']" @click="batchDelete">删除</a-button>-->
      </template>

      <template slot="operation" slot-scope="{record}">

        <div v-if="record.admin">默认角色，不可修改</div>
        <template v-else>
          <action-edit v-hasPermission="['role:update']" @click="edit(record)"></action-edit>
          <action-delete v-hasPermission="['role:delete']" @click="remove(record)"></action-delete>
        </template>
      </template>

    </common-table>


    <!-- 新增角色 -->
    <role-edit ref="roleEdit" @success="getList"></role-edit>

  </page-content>
</template>

<script>
  import RoleEdit from './RoleEdit'

  export default {
    components: { RoleEdit},
    data() {
      return {

      }
    },
    computed: {
      columns() {
        return [
          {
            title: '角色',
            dataIndex: 'roleName'
          },
          {
            title: '描述',
            dataIndex: 'remark',
          },
          {
            title: '创建时间',
            dataIndex: 'createdAt',
            sorter: true,
          },
          {
            title: '修改时间',
            dataIndex: 'updatedAt',
            sorter: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'}
          }
        ]
      }
    },

    methods: {
      add() {
        this.$refs.roleEdit.show()
      },
      edit(record) {
        this.$refs.roleEdit.show(record)
      },

      remove(record) {
        var that = this
        this.$confirm({
          title: '是否删除角色?',
          content: '该操作不可撤销',
          centered: true,
          onOk() {
            that.$delete('role/' + record.roleId).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },

      getList() {
        this.$refs.table.getData()
      },

      exprotExccel() {

      },

    }
  }
</script>

<style lang="less" scoped>
</style>
